import { createClient } from '@supabase/supabase-js'

const SUPABASE_URL = 'https://pwqqygcajhocobycrtek.supabase.co'
const SUPABASE_ANON_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InB3cXF5Z2NhamhvY29ieWNydGVrIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjUxMzY2NTAsImV4cCI6MjA0MDcxMjY1MH0.RLf9iRsis_h9bws9022SOhhj5b_im23vpYni7BcOBBc'
const COOKIE_DOMAIN = '.tryodu.com' // This will work for both tryodu.com and app.tryodu.com

export const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY, {
    auth: {
        persistSession: true,
        cookieOptions: {
            domain: COOKIE_DOMAIN,
            maxAge: 100000000,
            path: '/',
            sameSite: 'lax',
            secure: true,
        }
    }
})