import React, { useEffect, useState } from "react";
import Textbox from "./components/Textbox";
import Navbar from "./components/Navbar";
import { supabase } from './supabaseClient';

const App = () => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkSession = async () => {
      const pendingAuth = localStorage.getItem('pendingAuth');
      
      if (pendingAuth) {
        localStorage.removeItem('pendingAuth');
        
        // Wait for session to be established
        let attempts = 0;
        while (attempts < 5) {
          const { data } = await supabase.auth.getSession();
          if (data.session) {
            setSession(data.session);
            setLoading(false);
            return;
          }
          await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second before trying again
          attempts++;
        }
      }

      const { data } = await supabase.auth.getSession();
      setSession(data.session);
      setLoading(false);
    };

    checkSession();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);

      if (event === 'SIGNED_OUT' || event === 'USER_DELETED') {
        // delete cookies on sign out
        const expires = new Date(0).toUTCString();
        document.cookie = `my-access-token=; Domain=.tryodu.com; path=/; expires=${expires}; SameSite=Lax; secure`;
        document.cookie = `my-refresh-token=; Domain=.tryodu.com; path=/; expires=${expires}; SameSite=Lax; secure`;
      } else if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
        const maxAge = 100 * 365 * 24 * 60 * 60; // 100 years, never expires
        document.cookie = `my-access-token=${session.access_token}; Domain=.tryodu.com; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
        document.cookie = `my-refresh-token=${session.refresh_token}; Domain=.tryodu.com; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const setSessionFromCookies = async () => {
      const cookies = document.cookie.split(/\s*;\s*/).map(cookie => cookie.split('='));
      const accessTokenCookie = cookies.find(x => x[0] === 'my-access-token');
      const refreshTokenCookie = cookies.find(x => x[0] === 'my-refresh-token');

      if (accessTokenCookie && refreshTokenCookie) {
        const { data } = await supabase.auth.setSession({
          access_token: accessTokenCookie[1],
          refresh_token: refreshTokenCookie[1],
        });

        if (data.session) {
          setSession(data.session);
          setUser(data.session.user);
        }
      }
    };

    setSessionFromCookies();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!session) {
    window.location.href = 'https://tryodu.com/auth';
    return null;
  }

  return (
    <div className="h-screen">
      <Navbar user={user} />
      <Textbox />
    </div>
  );
};

export default App;