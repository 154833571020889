import React, { useState, useRef } from "react";
import axios from "axios";
import Dashboard from "./DashBoard";
import ExpandableButton from "./ExpandableButton";

const Textbox = () => {
  const [procedures, setProcedures] = useState([""]);
  const [medicalCoding, setMedicalCoding] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const inputRefs = useRef([]);
  const [currentButtonText, setCurrentButtonText] = useState("Select a format");

  const handleChange = (index, value) => {
    const newProcedures = [...procedures];
    newProcedures[index] = value;
    setProcedures(newProcedures);
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (index === procedures.length - 1) {
        setProcedures([...procedures, ""]);
        setTimeout(() => {
          inputRefs.current[index + 1]?.focus();
        }, 0);
      }
    }
  };
  
  const removeProcedure = (index) => {
    const newProcedures = procedures.filter((_, i) => i !== index);
    setProcedures(newProcedures);
  };

  const getMedicalCoding = async (apiEndpoint) => {
    setIsLoading(true);
    try {
      const response = await axios.get(apiEndpoint, {
        params: { 
          description: procedures.join("|"),
        },
        timeout: 50000,
      });

      if (response.data && response.data.ohip_code) {
        setMedicalCoding(response.data.ohip_code);
      } else {
        setMedicalCoding("No medical coding information received");
      }
    } catch (error) {
      setMedicalCoding("An error occurred while fetching medical coding");
    } finally {
      setIsLoading(false);
    }
  };

  const buttonOptions = [
    { 
      label: "OHIP Billing code", 
      action: () => getMedicalCoding("https://a2y8hmu7ua.execute-api.us-east-2.amazonaws.com/prod/coding/billing")
    },
    { 
      label: "OHIP Diagnostic Code", 
      action: () => getMedicalCoding("https://a2y8hmu7ua.execute-api.us-east-2.amazonaws.com/prod/coding/diagnostic")
    },
  ];

  const handleOptionSelect = (selectedOption) => {
    setCurrentButtonText(selectedOption);
  };

  return (
    <div className="flex h-screen bg-white">
      <div className="flex-1 flex flex-col p-5 relative">
        <div className="flex-1 overflow-y-auto mb-20"> {/* Increased bottom margin */}
          {procedures.map((procedure, index) => (
            <div key={index} className="mb-2 relative">
              <input
                ref={el => inputRefs.current[index] = el}
                type="text"
                value={procedure}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                placeholder="Enter procedure or diagnosis"
                className="w-full p-2 bg-white text-black rounded focus:outline-none"
              />
              {index !== procedures.length - 1 && (
                <button
                  onClick={() => removeProcedure(index)}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-red-500"
                >
                  ×
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="fixed bottom-5 left-5 w-[calc(50%-2.5rem)]"> {/* Adjusted width and positioning */}
          <ExpandableButton
            initialButtonText={currentButtonText}
            mainButtonAction={() => buttonOptions.find(option => option.label === currentButtonText).action()}
            isLoading={isLoading}
            options={buttonOptions}
            onOptionSelect={handleOptionSelect}
          />
        </div>
      </div>
      <div className="flex-1 p-5">
        <Dashboard medicalCoding={medicalCoding} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default Textbox;
