import React, { useState } from 'react';

const ExpandableButton = ({ initialButtonText, mainButtonAction, isLoading, options, onOptionSelect }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [buttonText, setButtonText] = useState(initialButtonText);

  const toggleExpand = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleMainButtonClick = () => {
    if (!isExpanded) {
      mainButtonAction();
    }
  };

  return (
    <div className="relative w-full">
      {isExpanded && (
        <div className="absolute w-full bottom-full mb-1 bg-white border border-gray-300 rounded shadow-lg">
          {options.map((option, index) => (
            <button
              key={index}
              className="w-full text-left px-4 py-2 hover:bg-gray-100 text-black"
              onClick={() => {
                setButtonText(option.label);
                setIsExpanded(false);
                onOptionSelect(option.label);
              }}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
      <button
        onClick={handleMainButtonClick}
        className="w-full btn no-animation bg-white text-black border border-gray-300 rounded hover:bg-gray-100 flex items-center justify-between"
        disabled={isLoading}
      >
        <span className="flex-grow text-left">
          {isLoading ? "Loading..." : buttonText}
        </span>
        <span
          onClick={toggleExpand}
          className="w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded"
        >
          <span 
            className="transform transition-transform duration-200" 
            style={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
          >
            ▼
          </span>
        </span>
      </button>
    </div>
  );
};

export default ExpandableButton;
