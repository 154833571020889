import React from "react";

const Navbar = ({ user }) => {
  return (
    <nav className="flex justify-between items-center p-4 bg-white border-b border-black">
      <div className="flex items-center">
        <img
          src="/odu.svg"
          alt="Company Logo"
          className="h-10 mr-2"
        />
        <p className="text-2xl text-black font-mono font-bold">ODU</p>
      </div>
      <div>
        {user && user.user_metadata && user.user_metadata.avatar_url && (
          <img
            src={user.user_metadata.avatar_url}
            alt="User Avatar"
            className="w-10 h-10 rounded-full"
          />
        )}
      </div>
    </nav>
  );
};

export default Navbar;
