import React from "react";

const Dashboard = ({ medicalCoding, isLoading }) => {
  return (
    <div className="h-full flex flex-col bg-white text-black">
      {isLoading ? (
        <div className="flex-1 flex items-center justify-center">
          <span className="loading loading-spinner loading-lg"></span>
        </div>
      ) : medicalCoding ? (
        <div className="flex-1 overflow-y-auto">
          <pre className="bg-white p-4 rounded-lg whitespace-pre-wrap">
            {medicalCoding}
          </pre>
        </div>
      ) : (
        <div className="flex-1 flex items-center justify-center">
          <p className="text-black">
            No medical coding information available yet.
          </p>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
